import React, { useState } from "react";
import Image from "mui-image";
import { Spinner } from "../Spinner";
import Fade from "@mui/material/Fade";

export const List = ({ addBodil, object, loading }) => {
  const housingType = {
    House: "Villa/Radhus",
    HousingCooperative: "Bostadsrätt",
    Cottage: "Fritidshus",
  };
  const [opacityRed, setOpacityRed] = useState(false);
  const [opacityGreen, setOpacityGreen] = useState(false);

  return (
    <Fade timeout={500} in={!loading}>
      <div
        className={`${opacityGreen && "opacity-70 bg-green-100"} ${
          opacityRed && "opacity-70 bg-red-100"
        } h-full bg-white shadow-md hover:shadow-xl relative transition-all duration-500  flex  rounded items-start text-start`}
      >
        <div
          className={`${opacityGreen && "opacity-70 bg-green-100"} ${
            opacityRed && "opacity-70 bg-red-100"
          } relative h-full w-3/4`}
        >
          <Image
            src={!loading ? object.thumb?.full?.url : "lagenhet.jpg"}
            alt=""
            height={250}
            showLoading={<Spinner />}
            duration={2000}
          />
        </div>
        <div className="px-8 py-4 h-full flex flex-col justify-between w-full">
          <div>
            <p className="uppercase text-xs font-light">
              {object?.address.area && object.address.area + ","}
              {object.address.city}
            </p>
            <h2 className="text-xl font-semibold">
              {object?.address.streetAddress}
            </h2>
            <h2 className="text-xl font-semibold">
              {object?.startingPrice} kr
            </h2>
            <p className="uppercase font-light text-xs">
              {housingType[object.estateType]} - {object.livingSpace} kvm -{" "}
              {object.numberOfRooms} rum
            </p>
          </div>
          <div className="items-end flex w-full gap-x-40 pb-2 px-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1"
              stroke="currentColor"
              onClick={() => {
                setOpacityRed(true);
                addBodil(object.objectReference, false);
              }}
              className="w-8 h-8 text-red-500 border-red-500 cursor-pointer hover:scale-125 duration-300 border p-1 rounded-full hover:drop-shadow-lg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1"
              stroke="currentColor"
              className="w-8 h-8 text-green-500 border-green-500 cursor-pointer hover:scale-125 duration-300 border p-1 rounded-full hover:drop-shadow-lg"
              onClick={() => {
                setOpacityGreen(true);
                addBodil(object.objectReference, true);
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </div>
        </div>
      </div>
    </Fade>
  );
};
