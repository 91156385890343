// spara anv och lösen i cookie (anv:lösen)
// får jag 401, radera cookie och routa till login och gör om
// får jag 400 fortsätt till dashboard

import React, { useState } from "react";

export const Login = ({ statusCode, setCookie }) => {
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(null);

  const login = async () => {
    const login = await fetch(
      `${process.env.REACT_APP_OBJECT_API}/api/bodil/login`,
      {
        method: "POST",
        body: JSON.stringify({
          username: userName,
          password: password,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((res) => res)
      .catch((err) => err);

    if (login.status < 199 || login.status > 399) {
      setError(login.status);
    } else {
      const token = await login.json();
      setCookie("bodil-token", `${token}`, {
        maxAge: 72000,
      });
    }
  };

  return (
    <form
      className={` w-1/2 mx-auto flex gap-y-4 justify-center items-center h-96 flex-col`}
    >
      <input
        type="text"
        className="border py-2 px-8 rounded"
        onChange={(e) => setUserName(e.target.value)}
        placeholder="Användarnamn"
      />
      <input
        type="password"
        className="border py-2 px-8 rounded"
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Lösenord"
      />
      {error && error !== 404 && (
        <p className="text-red-600"> Fel användare eller lösenord</p>
      )}
      {error && error === 404 && (
        <p className="text-red-600"> Något gick fel, försök igen senare</p>
      )}
      <button
        className="border py-2 px-4 rounded"
        onClick={(e) => {
          e.preventDefault();
          login();
        }}
      >
        Logga in
      </button>
    </form>
  );
};
